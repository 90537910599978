'use client'

import React, { useEffect, useState } from 'react'

import { getRepos } from 'db/actions'
import { SelectRepo } from 'db/schema'
import SearchButton from 'components/searchButton'

export default function Search() {
  const [repos, setRepos] = useState<SelectRepo[]>([])
  useEffect(() => {
    getRepos().then(setRepos)
  })
  return (
    <div className='/* size-full */'>
      {repos.map(repo => {
        console.log(repo)
        return (
          <SearchButton
            key={repo.org + repo.repo}
            repo={repo.repo}
            org={repo.org ?? ''}
          />
        )
      })}
    </div>
  )
}
